import React from "react"
import { graphql } from "gatsby"
import tw from "twin.macro"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import StaticHero from "@components/staticHero"
import Section from "@components/section"
import Container from "@components/container"
import LearnMore from "@components/learnMore"
import Callout from "@components/callout"
import KeyPoints from "@components/keyPoints"
import AccordionStudyResult from "@components/accordionStudyResult"
import { Heading2 } from "@styles/ui"

const XywavEfficacy = ({ location, data }) => {
  return (
    <Layout location={location} mainBg="lilac">
      <Seo
        title="XYWAV Efficacy for Idiopathic Hypersomnia | XYWAV for IH"
        description="Read about the clinical studies of XYWAV® (calcium, magnesium, potassium, and sodium oxybates) and efficacy in treating Idiopathic Hypersomnia in adult patients. Find the Epworth Sleepiness Scale (ESS) to help you learn more about assessing this sleep disorder. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />

      <div>
        <StaticHero
          eyebrow="STUDY RESULTS WITH XYWAV"
          imageData={data.heroImage}
          alt="Lady playing cards with young boy"
          jumpLinks={[
            { text: "XYWAV vs placebo", link: "#xywav-placebo" },
            { text: "Study results", link: "#study-results" },
          ]}
          gradient="purple"
        >
          Study results for XYWAV in adults with Idiopathic
          Hypersomnia&nbsp;(IH)
        </StaticHero>

        <Section bgColour={tw`bg-lilac`} id="xywav-placebo">
          <Container>
            <img
              src={"/images/xywav-logo-large-callout.svg"}
              tw="hidden absolute -top-[75px] -left-[380px] opacity-50 lg:block"
              width="780"
              alt=""
            />
            <h2
              css={[Heading2, tw`text-center text-[24px] mb-10 xl:(mx-auto)`]}
            >
              A clinical study of nightly XYWAV showed that, compared to placebo
              (a&nbsp;treatment that is inactive), XYWAV:
            </h2>
            <div tw="flex flex-col text-sm md:(flex-row justify-center) xl:(text-xl)">
              <KeyPoints points={keyPoints} mdWrap />
            </div>
          </Container>
        </Section>

        <Section id="study-results" bgColour={tw`bg-lilac`}>
          <Container>
            <img
              src={"/images/xywav-logo-large-callout.svg"}
              tw="hidden absolute top-6 -left-[330px] z-10 opacity-50 lg:block"
              width="550px"
              alt=""
            />
            <h2
              css={[
                Heading2,
                tw`text-center mb-6 xl:(max-w-[1100px] mx-auto mb-10 scroll-mt-8)`,
              ]}
            >
              What changes in excessive daytime sleepiness were seen in people
              during the XYWAV clinical study?
            </h2>
            <ul tw="list-disc [padding-inline-start: 20px] text-base xl:(text-xl)">
              <li tw="mb-5">
                Excessive daytime sleepiness (EDS) is measured by a screener
                called the <strong>Epworth Sleepiness Scale (ESS).</strong>
              </li>
              <li>
                The ESS measures EDS on a scale from{" "}
                <strong>
                  <nobr>0 to 24.</nobr>
                </strong>
              </li>
            </ul>
            <div tw="my-10">
              <AccordionStudyResult
                heading={accordionData.trailData.heading}
                content={accordionData.trailData.content}
              />
            </div>
            <div tw="my-10">
              <AccordionStudyResult
                heading={accordionData.xywavWorkedData.heading}
                content={accordionData.xywavWorkedData.content}
              />
            </div>
          </Container>
        </Section>
        <Callout
          heading="Not sure of your ESS score?"
          cta={{
            text: "Download ESS",
            url: "/pdf/ESS_Downloadable_PDF.pdf",
            external: true,
            noInterstitial: true,
          }}
          sourceText={
            <>
              <p tw="mb-1 text-[12px] lg:text-xs">
                Source: Johns MW. A new method for measuring daytime sleepiness:
                the Epworth Sleepiness Scale. <i>Sleep.</i>
                1991;14(6):540-545.
              </p>
              <p tw="text-[12px] lg:text-xs">
                The Epworth Sleepiness Scale (ESS) is intended to measure EDS, a
                symptom of Idiopathic Hypersomnia. It is not intended to make an
                Idiopathic Hypersomnia diagnosis.
              </p>
            </>
          }
        >
          <>
            <p tw="mb-8">
              Find out your level of daytime sleepiness and be sure to share
              your results with your doctor.
            </p>
          </>
        </Callout>

        {/* // end of sleepiness ref/content */}
        <Section id="PGIc" bgColour={tw`bg-lilac`}>
          <Container>
            <img
              src={"/images/xywav-logo-large-callout.svg"}
              tw="hidden absolute -top-[30px] -left-[330px] z-10 opacity-50 lg:block"
              width="550px"
              alt=""
            />
            <h2
              css={[
                Heading2,
                tw`text-center mb-6 xl:(max-w-[1100px] mx-auto mb-10)`,
              ]}
            >
              What changes in overall Idiopathic Hypersomnia symptoms were seen
              in people during the XYWAV clinical study?
            </h2>
            <ul tw="list-disc [padding-inline-start: 20px] text-base xl:(text-xl)">
              <li tw="mb-5">
                The <strong>Patient Global Impression of Change (PGIc)</strong>{" "}
                screener was used to measure how well XYWAV helps treat symptoms
                of Idiopathic Hypersomnia overall.
              </li>
              <li tw="mb-5">
                The PGIc asked participants to rate how their Idiopathic
                Hypersomnia felt using a{" "}
                <strong>
                  <nobr>7-point</nobr> scale ranging from “very much improved”
                  to “very much worse.”
                </strong>
              </li>
            </ul>
            <div tw="mt-10">
              <AccordionStudyResult
                heading={accordionData.patientPGIData.heading}
                content={accordionData.patientPGIData.content}
              />
            </div>
          </Container>
        </Section>
        <Section id="IHSS" bgColour={tw`bg-lilac`}>
          <Container>
            <h2
              css={[
                Heading2,
                tw`text-center mb-6 xl:(max-w-[1100px] mx-auto mb-10)`,
              ]}
            >
              What changes in Idiopathic Hypersomnia symptoms were seen in
              people during the XYWAV clinical study?
            </h2>
            <ul tw="list-disc [padding-inline-start: 20px] text-base xl:(text-xl)">
              <li tw="mb-5">
                Severity and frequency of Idiopathic Hypersomnia symptoms is
                measured by a screener called the{" "}
                <strong>Idiopathic Hypersomnia Severity Scale (IHSS).</strong>
              </li>
              <li tw="mb-5">
                <strong>The IHSS is a unique screener—</strong>it is the only
                one validated to measure Idiopathic Hypersomnia symptoms.
              </li>
              <li tw="mb-5">
                The IHSS asks participants to answer 14 questions related to the
                frequency and intensity of a{" "}
                <strong>
                  range of symptoms—including excessive sleepiness, long sleep
                  time, cognitive impairment, and sleep inertia.
                </strong>
              </li>
              <li tw="mb-5">
                The IHSS measures frequency and severity of Idiopathic
                Hypersomnia symptoms on a scale of <strong>0 to 50.</strong>
              </li>
            </ul>
            <div tw="mt-10">
              <AccordionStudyResult
                heading={accordionData.trailData2.heading}
                content={accordionData.trailData2.content}
              />
            </div>
            <div tw="my-10">
              <AccordionStudyResult
                heading={accordionData.xywavWorkedData2.heading}
                content={accordionData.xywavWorkedData2.content}
              />
            </div>
          </Container>
        </Section>
        <Callout
          heading="Do you know your IHSS score?"
          cta={{
            text: "Download IHSS",
            url: "/pdf/IHSS_Downloadable_PDF.pdf",
            external: true,
            noInterstitial: true,
          }}
          sourceText={
            <>
              <p tw="mb-2 text-[12px] lg:text-xs">
                Source: Dauvilliers Y, Evangelista E, Barateau L, et al.
                Measurement of symptoms in idiopathic hypersomnia: the
                Idiopathic Hypersomnia Severity Scale.{" "}
                <span tw="italic">Neurology</span>. 2019;92(15):e1754-e1762.
              </p>
              <p tw="text-[12px] lg:text-xs">
                The Idiopathic Hypersomnia Severity Scale is intended to measure
                the frequency and severity of Idiopathic Hypersomnia symptoms.
                It is not intended to make an Idiopathic Hypersomnia diagnosis.
              </p>
            </>
          }
        >
          <>
            <p tw="mb-8">
              Rate the severity and frequency of your Idiopathic Hypersomnia
              symptoms and be sure to share your results with your doctor.
            </p>
          </>
        </Callout>
        <LearnMore ih colour="purple" ctas={learnMoreCtas} />
      </div>
    </Layout>
  )
}

export default XywavEfficacy

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "efficacy/hero.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
  }
`

const learnMoreCtas = [
  {
    text: "Ask your doctor if XYWAV is right for you",
    url: "/idiopathic-hypersomnia/talking-to-your-doctor/",
  },
  {
    text: "Find out about the possible side effects of XYWAV",
    url: "/idiopathic-hypersomnia/side-effects/",
  },
  {
    text: "Learn more about taking XYWAV",
    url: "/idiopathic-hypersomnia/dosing/",
  },
]
const keyPoints = [
  {
    image: "/images/icons/excessive-daytime-sleepiness-icon.svg",
    imageAlt: "Person sleeping at desk icon",
    text: (
      <p tw="font-avenir font-bold text-[15px] leading-[19.5px] px-7 xl:(text-[18px] leading-[25.5px])">
        Helped treat excessive daytime sleepiness
      </p>
    ),
    cta: {
      url: "#study-results",
      text: "See results",
    },
  },
  {
    image: "/images/icons/round/clipboard-icon.svg",
    imageAlt: "Clipboard icon",
    text: (
      <p tw="font-avenir font-bold text-[15px] leading-[19.5px] px-8 xl:(text-[18px] leading-[25.5px])">
        Helped with overall Idiopathic Hypersomnia symptoms (patient reported)
      </p>
    ),
    cta: {
      url: "#PGIc",
      text: "See results",
    },
  },
  {
    image: "/images/icons/round/icon-nighttime-sleep-noshadow.svg",
    imageAlt: "Nighttime moon and stars icon",
    text: (
      <p tw="font-avenir font-bold text-[15px] leading-[19.5px] xl:(text-[18px] leading-[25.5px])">
        Helped with the severity and frequency of Idiopathic Hypersomnia
        symptoms like long sleep time, cognitive impairment, and sleep inertia
      </p>
    ),
    cta: {
      url: "#IHSS",
      text: "See results",
    },
  },
]

const accordionData = {
  trailData: {
    heading: <>During the first part of the study</>,
    content: (
      <>
        <picture tw="block mx-auto max-w-full py-4 xl:(py-8)">
          <source
            srcSet={"/images/chart-ess-desktop.svg"}
            media="(min-width: 1023px)"
          />
          <img
            src={"/images/chart-ess-mobile.svg"}
            alt="Epworth Sleepiness Scale diagram"
            tw="mx-auto"
          />
        </picture>
        <div tw="px-2.5 pb-7 lg:(px-12 pb-12)">
          <p tw="text-studio mb-3.5 lg:text-xl">
            Note: The information collected during the first part of the study
            was not meant to measure how well XYWAV worked.
          </p>
          <p tw="text-[12px] lg:text-xs">
            Stable dose = the dose of XYWAV that worked best for each person.
          </p>
        </div>
      </>
    ),
  },
  xywavWorkedData: {
    heading: (
      <>During the period of the study that measured how well XYWAV worked</>
    ),
    content: (
      <>
        <picture tw="flex justify-center">
          <source
            srcSet={"/images/ess-average-desktop.svg"}
            media="(min-width: 1023px)"
          />
          <img
            src={"/images/ess-average-mobile.svg"}
            alt="ESS study results"
            tw="flex-1 p-4 md:(mx-auto p-[50px])"
          />
        </picture>
        <div tw="px-2.5 pb-7 md:(mx-auto mx-[30px] px-[50px]) lg:(px-12 pb-12)">
          <p tw="mb-3.5 lg:text-xl text-center">
            Results shown here used average scores, which were then rounded up
            or down. That’s why the difference between scores may not always
            appear to add up.
          </p>
        </div>
      </>
    ),
  },
  patientPGIData: {
    heading: (
      <>
        Patient global impression of change (PGI<span tw="lowercase">c</span>)
      </>
    ),
    content: (
      <>
        <picture tw="block mx-auto max-w-full py-4 lg:(py-8)">
          <source
            srcSet={"/images/chart-pgi-desktop.svg"}
            media="(min-width: 1023px)"
          />
          <img
            src={"/images/chart-pgi-mobile.svg"}
            alt="Patient Global Impression of Change study results"
            tw="mx-auto"
          />
        </picture>
        <p tw="text-[12px] px-2.5 pb-7 xl:(text-xs px-12 pb-12)">
          Worsening was defined as “minimally, much worse, or very much worse”
          Idiopathic Hypersomnia overall. Improvement was defined as “minimally,
          much improved, or very much improved” Idiopathic Hypersomnia overall.
        </p>
      </>
    ),
  },
  trailData2: {
    heading: <>During the first part of the study</>,
    content: (
      <>
        <picture tw="block mx-auto max-w-full py-4 lg:(py-8)">
          <source
            srcSet={"/images/chart-ihss-desktop.svg"}
            media="(min-width: 1023px)"
          />
          <img
            src={"/images/chart-ihss-mobile.svg"}
            alt="Idiopathic Hypersomnia severity scale results"
            tw="mx-auto"
          />
        </picture>
        <div tw="px-2.5 pb-7 lg:(px-12 pb-12)">
          <p tw="text-studio mb-3.5 lg:text-xl">
            Note: The information collected during the first part of the study
            was not meant to measure how well XYWAV worked.
          </p>
          <p tw="text-[12px] lg:text-xs">
            Stable dose = the dose of XYWAV that worked best for each person.
          </p>
        </div>
      </>
    ),
  },
  xywavWorkedData2: {
    heading: (
      <>During the period of the study that measured how well XYWAV worked</>
    ),
    content: (
      <>
        <picture tw="flex justify-center">
          <source
            srcSet={"/images/ihss-average-desktop.svg"}
            media="(min-width: 1023px)"
          />
          <img
            src={"/images/ihss-average-mobile.svg"}
            alt="IHSS study results"
            tw="flex-1 p-4 md:(mx-auto p-[50px])"
          />
        </picture>
        <div tw="px-2.5 pb-7 md:(mx-[30px] px-[50px]) lg:(px-12 pb-12)">
          <p tw="mb-3.5 lg:text-xl text-center">
            Results shown here used average scores, which were then rounded up
            or down. That’s why the difference between scores may not always
            appear to add up.
          </p>
        </div>
      </>
    ),
  },
}
